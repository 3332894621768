import React from 'react';
import { graphql, withPrefix } from 'gatsby';
import styled from '@emotion/styled';
import FunctinosTemplate from 'components/Templates/FunctinosTemplate';
import { FluidObject } from 'gatsby-image';
import { Helmet } from 'react-helmet';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface uxAnalysisProps {
  location: {
    pathname: string;
  };
  data: {
    logoNerdFactoryImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    topFunctionImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img1: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img2: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img3: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    thumbnail: {
      publicURL: string;
    };
  };
}

const uxAnalysis = ({ data, location }: uxAnalysisProps) => {
  const title = 'VODA | 기능 | 사용성(UX) 분석';
  const desc = '사용자의 생각, 의도를 이해할 수 있도록 VODA가 도와드립니다';
  return (
    <Component>
      <Helmet>
        <title>VODA | 기능 | 사용성(UX) 분석</title>
        <meta
          name="description"
          content="사용자의 생각, 의도를 이해할 수 있도록 VODA가 도와드립니다"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />

        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="<https://voda.nerdfactory.ai/functions/uxAnalysis>"
        />
        <meta property="og:image" content={data.thumbnail.publicURL} />
        <meta property="og:description" content={desc} />
        <meta property="og:site_name" content={title} />
        <script src={withPrefix('script.js')} type="text/javascript" />
      </Helmet>
      <FunctinosTemplate
        location={location}
        title={'시선의\\n흐름을 VODA'}
        header={{
          main: '사용자의 생각, 시선, 의도\\n그 모든 흐름을\\nVODA와 함께 읽을 수 있습니다',
          sub: '시선의 흐름을 VODA',
        }}
        isAIvory={false}
        functionData={[
          {
            IllustImg: data.img1.childImageSharp.fluid,
            descData: {
              title:
                '사용자가 어떤 요소를\\n얼마나 클릭했는지,\\n사용자의 관심사를\\n확인해보세요',
              list: [
                '1. 클릭 분포 및 클릭 집중 시각화',
                '2. 페이지 내 요소의 클릭 관련 지표',
                '- 클릭 순위',
                '- 클릭 횟수',
                '- 클릭 비율',
              ],
            },
            engTitle: 'CLICK HEATMAP',
            korTitle: '클릭 히트맵',
            isBackGround: true,
            isDescLeft: false,
            width: 648,
            key: '0',
          },
          {
            IllustImg: data.img2.childImageSharp.fluid,
            descData: {
              title:
                '사용자의 스크롤링을 읽고\\n어떤 콘텐츠 영역을\\n주목했는지 알 수있습니다',
              list: ['1. 페이지 높이에 따른 평균 체류시간', '2. 높이별 도달률'],
            },
            engTitle: 'SCROLL HEATMAP',
            korTitle: '스크롤 히트맵',
            isBackGround: false,
            isDescLeft: true,
            width: 648,
            key: '1',
          },
          {
            IllustImg: data.img3.childImageSharp.fluid,
            descData: {
              title:
                '사용자가 어떤 페이지에서 유입되었고,\\n어떤 페이지로 전환되었는지\\n알 수있습니다',
              list: ['1. 문구 고려'],
            },
            engTitle: 'PAGE FLOW',
            korTitle: '페이지 흐름 분석',
            isBackGround: true,
            isDescLeft: false,
            width: 648,
            key: '2',
          },
        ]}
        img={data.topFunctionImg.childImageSharp.fluid}
        logoNerdFactoryImg={data.logoNerdFactoryImg.childImageSharp.fluid}
      />
    </Component>
  );
};

export default uxAnalysis;

export const queryData = graphql`
  query {
    logoNerdFactoryImg: file(name: { eq: "logo-nerd-factory@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    topFunctionImg: file(name: { eq: "img-top-function@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img1: file(name: { eq: "img-clickHeatmap-v2" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img2: file(name: { eq: "img-scroll-v2" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img3: file(name: { eq: "img-pageflow" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thumbnail: file(name: { eq: "thumbnail-1280-720" }) {
      publicURL
    }
  }
`;
